/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tour, AdminService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import "./../Home/calendar.css";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";

export function OurTours({ isHomePage = false }: { isHomePage?: boolean }) {
  const { t, i18n } = useTranslation();

  const [tours, setTours] = useState<
    (Tour & {
      endDates: Date[];
    })[]
  >([]);

  // get date from query params
  const urlParams = new URLSearchParams(window.location.search);
  const dateFromUrl = urlParams.get("date");

  const [date, setDate] = useState<Date | null>(
    dateFromUrl ? new Date(parseInt(dateFromUrl)) : null
  );

  useEffect(() => {
    async function getTours() {
      const tours = await AdminService.getTours().catch((err) => {
        console.error(err);
        return [];
      });
      setTours(
        tours.map((tour) => ({
          ...tour,
          endDates:
            tour.tourDates.map(
              (tourDate) =>
                new Date(
                  new Date(tourDate).setDate(
                    new Date(tourDate).getDate() + tour.numberOfDays - 1
                  )
                )
            ) || []
        }))
      );

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ items: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "view_item_list",
        currency: "EUR",
        item_list_name: "OurTours",
        items: tours.map((tour, index) => ({
          item_id: tour.id,
          item_name: tour.name,
          item_category: tour.startLocation,
          item_variant: "",
          price: tour.packages[0].price,
          quantity: 1,
          index: index + 1
        }))
      });
    }

    getTours();
  }, []);

  return (
    <>
      {!isHomePage && (
        <>
          {localStorage.getItem("i18nextLng") === "ro" ? (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tururile-noastre`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tururile-noastre`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tururile-noastre?lang=en`}
              />

              <title>
                Tururi off-road 4x4 cu ghid - Descopera circuitele - First Gear
                Club
              </title>
              <meta
                name="description"
                content="Descopera tururile off-road oferite de First Gear Club si exploreaza peisajele spectaculoase ale Romaniei, de la paduri dense la trasee montane stancoase ⭐ Aventura garantata ⭐ Plecari din Bucuresti & Brasov"
              />
            </Helmet>
          ) : (
            <Helmet>
              <link
                rel="canonical"
                href={`https://firstgearclub.com/tururile-noastre`}
              />

              <link
                rel="alternate"
                hrefLang="ro"
                href={`https://firstgearclub.com/tururile-noastre`}
              />
              <link
                rel="alternate"
                hrefLang="en"
                href={`https://firstgearclub.com/tururile-noastre?lang=en`}
              />

              <title>
                Guided 4x4 Off-Road Tours - Discover the Routes - First Gear
                Club
              </title>
              <meta
                name="description"
                content="Discover the off-road tours offered by First Gear Club and explore Romania's spectacular landscapes, from dense forests to rocky mountain trails ⭐ Guaranteed adventure ⭐ Tours from Bucharest & Brasov."
              />
            </Helmet>
          )}
        </>
      )}

      {!isHomePage && (
        <Breadcrumbs
          items={[
            {
              link: `#`,
              name: `${
                !isHomePage
                  ? `${
                      date
                        ? t("home.subSearchSection.titleAvailable")
                        : t("home.subSearchSection.title")
                    }`
                  : `${
                      date
                        ? t("home.subSearchSection.titleAvailable")
                        : t("home.subSearchSection.title")
                    }`
              }`
            }
          ]}
          title={`${
            !isHomePage
              ? `${
                  date
                    ? t("home.subSearchSection.titleAvailable")
                    : t("home.subSearchSection.title")
                }`
              : `${
                  date
                    ? t("home.subSearchSection.titleAvailable")
                    : t("home.subSearchSection.title")
                }`
          }`}
        />
      )}
      <section className="section">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <Row className="justify-content-center">
              <Col md={6} className="col-12">
                {!isHomePage ? (
                  <h1 className="primaryFont">
                    {date
                      ? t("home.subSearchSection.titleAvailable")
                      : t("home.subSearchSection.title")}
                  </h1>
                ) : (
                  <h2 className="primaryFont">
                    {date
                      ? t("home.subSearchSection.titleAvailable")
                      : t("home.subSearchSection.title")}
                  </h2>
                )}
                <p className="w-100" style={{ maxWidth: "inherit" }}>
                  {t("home.subSearchSection.text")}
                </p>
              </Col>
            </Row>
          </div>
          {/* /Heading title */}
          <div className="row">
            <div className="popular-slider-group">
              <div className="rental-deal-slider ">
                {date && (
                  <Row className="mb-4 justify-content-center">
                    <Col md={4} className="col-12 p-4">
                      <Calendar
                        value={date}
                        onChange={(e) => {
                          const localDate = e as Date;
                          if (!localDate) {
                            setDate(null);
                            return;
                          }
                          // change state of date
                          setDate(
                            new Date(
                              Date.UTC(
                                localDate.getFullYear(),
                                localDate.getMonth(),
                                localDate.getDate()
                              )
                            )
                          );
                          // change url
                          window.history.replaceState(
                            {},
                            "",
                            `?date=${new Date(
                              Date.UTC(
                                localDate.getFullYear(),
                                localDate.getMonth(),
                                localDate.getDate()
                              )
                            )
                              .getTime()
                              .toString()}`
                          );
                        }}
                        className="w-100"
                        minDate={
                          new Date(new Date().setDate(new Date().getDate() + 1))
                        }
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  {tours
                    .filter((tour) => {
                      if (!date) {
                        return true;
                      }
                      return tour.endDates.some((endDate, index) => {
                        return (
                          date.getTime() >=
                            new Date(tour.tourDates[index]).getTime() &&
                          date.getTime() <= endDate.getTime()
                        );
                      });
                    })
                    .map((tour, index) => (
                      <Col
                        md={4}
                        className="col-12 rental-car-item"
                        key={tour.id}
                      >
                        <div className="listing-item mb-0">
                          <div className="listing-img">
                            <a
                              href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/${date ? `${date.getTime()}/` : ""}`}
                              onClick={() => {
                                // @ts-ignore
                                window.dataLayer = window.dataLayer || [];
                                // @ts-ignore
                                // @ts-ignore
                                window.dataLayer.push({ items: null });
                                // @ts-ignore
                                window.dataLayer.push({
                                  event: "select_item",
                                  currency: "EUR",
                                  item_list_name: "OurTours",
                                  items: [
                                    {
                                      item_id: tour.id,
                                      item_name: tour.name,
                                      item_category: tour.startLocation,
                                      item_variant: "",
                                      price: tour.packages[0].price,
                                      quantity: 1,
                                      index: index + 1
                                    }
                                  ]
                                });
                              }}
                            >
                              <img
                                src={`${process.env.REACT_APP_STORAGE_BASE_URL}/tours/${tour?.id}/${tour?.photos[0]}`}
                                className="img-fluid"
                                alt={tour.name}
                                loading="lazy"
                              />
                            </a>
                          </div>
                          <div className="listing-content">
                            <div className="listing-features">
                              <div className="fav-item-rental">
                                <div
                                  className="featured-text"
                                  style={{ background: `#${tour.color}` }}
                                >
                                  {t("home.tourSection.featureText", {
                                    price: tour.packages[0].price * 0.95
                                  })}
                                </div>
                              </div>
                              <div className="list-rating">
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <i className="fas fa-star filled" />
                                <span>(5.0)</span>
                              </div>
                              <h2 className="listing-title">
                                <a
                                  href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/`}
                                >
                                  {i18n.language === "ro"
                                    ? tour.nameRo
                                    : tour.name}
                                </a>
                              </h2>
                              <p>
                                <i className="feather icon-map-pin me-2" />
                                {t("home.tourSection.startLocationLabel")}:{" "}
                                <b>{tour.startLocation}</b>
                              </p>
                              <p>
                                <i className="feather icon-clock me-2" />
                                {t("home.tourSection.durationLabel")}:{""}
                                <b>
                                  {" "}
                                  {tour.numberOfDays}{" "}
                                  {tour.numberOfDays === 1
                                    ? t(
                                        "home.tourSection.numberOfDaysLabel_one"
                                      )
                                    : t(
                                        "home.tourSection.numberOfDaysLabel_other"
                                      )}
                                </b>
                                {date && tour.numberOfDays > 1 ? (
                                  <span className="text-danger">
                                    {" "}
                                    {t("home.tourSection.warningDifferentDate")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="listing-button">
                              <a
                                href={`/tur/${tour?.name.replaceAll(" ", "-")}-${tour.id}/${date ? `${date.getTime()}/` : ""}`}
                                className="btn btn-order"
                                onClick={() => {
                                  // @ts-ignore
                                  window.dataLayer = window.dataLayer || [];
                                  // @ts-ignore
                                  // @ts-ignore
                                  window.dataLayer.push({ items: null });
                                  // @ts-ignore
                                  window.dataLayer.push({
                                    event: "select_item",
                                    currency: "EUR",
                                    item_list_name: "OurTours",
                                    items: [
                                      {
                                        item_id: tour.id,
                                        item_name: tour.name,
                                        item_category: tour.startLocation,
                                        item_variant: "",
                                        price: tour.packages[0].price,
                                        quantity: 1,
                                        index: index + 1
                                      }
                                    ]
                                  });
                                }}
                              >
                                <span>
                                  <i className="feather icon-calendar me-2" />
                                </span>
                                {t("home.tourSection.buttonLabel")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
