import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
// import { Testimonials } from "../../components/Testimonials";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Col } from "react-bootstrap";
import { cars } from "../../constants";

const AboutUs = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);

  return (
    <>
      {localStorage.getItem("i18nextLng") === "ro" ? (
        <Helmet>
          <link rel="canonical" href="https://firstgearclub.com/despre-noi" />

          <link
            rel="alternate"
            hrefLang="ro"
            href="https://firstgearclub.com/despre-noi"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href="https://firstgearclub.com/despre-noi?lang=en"
          />

          <title>Despre First Gear Club | Aventuri Off-Road</title>
          <meta
            name="description"
            content="Descopera echipa First Gear Club, pasionata de off-road, condusa de expertul George Grigorescu ⭐ Oferim experiente unice si sigure in salbaticia nealterata a Romaniei, cu respect pentru mediu."
          />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="canonical" href="https://firstgearclub.com/despre-noi" />

          <link
            rel="alternate"
            hrefLang="ro"
            href="https://firstgearclub.com/despre-noi"
          />
          <link
            rel="alternate"
            hrefLang="en"
            href="https://firstgearclub.com/despre-noi?lang=en"
          />

          <title>About First Gear Club | Off-Road Adventures</title>
          <meta
            name="description"
            content="Discover the First Gear Club team, passionate about off-roading led by expert George Grigorescu ⭐ We offer unique and safe experiences in Romania's unspoiled wilderness with respect for nature."
          />
        </Helmet>
      )}

      <Breadcrumbs
        items={[{ link: "", name: t("header.navLinks.aboutUs") }]}
        title={t("header.navLinks.aboutUs")}
      />
      {/* About */}
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <div className="about-exp">
                  <span>{t("aboutUs.welcomeSection.leftLabel")}</span>
                </div>
                <div className="abt-img">
                  <img
                    src="assets/img/about-us.webp"
                    className="img-fluid"
                    alt="About us"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <h6>{t("aboutUs.welcomeSection.aboveTitle")}</h6>
                <h1 className="h2 text-dark primaryFont">
                  {t("aboutUs.welcomeSection.title")}
                </h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.welcomeSection.text")
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /About */}
      <section className="pricing-section pricing-page pricing-section-bottom">
        <div className="container">
          {/* App Available */}
          <div className="user-app-group">
            <div className="app-left">
              <img
                src="assets/img/bg/app-left.png"
                className="img-fluid"
                alt="App Available"
                loading="lazy"
              />
            </div>
            <div className="app-right">
              <img
                src="assets/img/bg/app-right.png"
                className="img-fluid"
                alt="App Available"
                loading="lazy"
              />
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="userapp-heading">
                  <h2 data-aos="mb-5 " className="primaryFont">
                    {t("aboutUs.ourMissionSection.title")}
                  </h2>
                  <p data-aos="mb-5">
                    {t("aboutUs.ourMissionSection.firstParagraph")}
                  </p>
                  <p className="mb-0">
                    {t("aboutUs.ourMissionSection.secondParagraph")}
                  </p>
                </div>
              </div>
              <div className="col-lg-5 d-none d-lg-block align-content-center">
                <div className="car-holder">
                  <img
                    className="app-car img-fluid"
                    src="assets/img/app-car.jpg"
                    alt="App Available"
                    data-aos="mb-5"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* /App Available */}
        </div>
      </section>

      <section className="boats-marketplace-sec">
        <div className="sec-round-colors">
          <span className="bg-orange round-small" />
          <span className="bg-dark-blue round-small" />
        </div>
        <div className="sec-bg">
          <img
            src="assets/img/bg/dotted-round-bg.png"
            alt="Bg"
            loading="lazy"
          />
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="sec-col-left-imgs">
                <span className="sec-left-one">
                  <img
                    src="assets/img/bg/sec-modal-img-01.jpg"
                    className="img-fluid"
                    alt="Img"
                    loading="lazy"
                  />
                </span>
                <span className="sec-left-two">
                  <img
                    src="assets/img/bg/sec-modal-img-02.jpg"
                    className="img-fluid"
                    alt="Img"
                    loading="lazy"
                  />
                </span>
                <div className="experience-info">
                  <h5>
                    30+{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("aboutUs.meetOurGuideSection.experienceLabel")
                      }}
                    ></span>
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-header-two">
                <h2 className="primaryFont">
                  {t("aboutUs.meetOurGuideSection.title")}
                </h2>
                <h4>{t("aboutUs.meetOurGuideSection.subtitle")} </h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("aboutUs.meetOurGuideSection.text")
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        {" "}
        {/* About */}
        <section className="section about-sec">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-img">
                  <div className="abt-img">
                    <img
                      src="assets/img/no-trace.jpeg"
                      className="img-fluid"
                      alt="Leave No Trace"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <h6>{t("leaveNoTrace.aboveTitle")}</h6>
                  <h1 className="h2 text-dark primaryFont">
                    {t("leaveNoTrace.title")}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("leaveNoTrace.mainText")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /About */}
        <section className="section about-sec">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-lg-12">
                <div className="about-content">
                  <h2 className="primaryFont">
                    {t("leaveNoTrace.principlesSection.title")}
                  </h2>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("leaveNoTrace.principlesSection.principles")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing-section pricing-page pricing-section-bottom">
          <div className="container">
            {/* App Available */}
            <div className="user-app-group">
              <div className="app-left">
                <img
                  src="assets/img/bg/app-left.png"
                  className="img-fluid"
                  alt="App Available"
                  loading="lazy"
                />
              </div>
              <div className="app-right">
                <img
                  src="assets/img/bg/app-right.png"
                  className="img-fluid"
                  alt="App Available"
                  loading="lazy"
                />
              </div>
              <div className="row">
                <div className="col-lg-7">
                  <div className="userapp-heading">
                    <h2 data-aos="mb-5" className="">
                      {t("leaveNoTrace.importanceSection.title")}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t("leaveNoTrace.importanceSection.text")
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-5 d-none d-lg-block align-content-center">
                  <div className="car-holder">
                    <img
                      className="app-car img-fluid"
                      src="assets/img/app-car.jpg"
                      alt="App Available"
                      data-aos="mb-5"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* /App Available */}
          </div>
        </section>
        <section className="section about-sec">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-lg-12">
                <div className="about-content">
                  <h2 className="primaryFont">Positive Impact</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("leaveNoTrace.impactSection.text")
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="boats-marketplace-sec">
          <div className="sec-round-colors">
            <span className="bg-orange round-small" />
            <span className="bg-dark-blue round-small" />
            <span className="bg-dark-blue round-small" />
            <span className="bg-dark-blue round-big" />
          </div>
          <div className="container">
            <div className="yacht-owner-card">
              <div className="sec-bg">
                <img
                  src="assets/img/bg/sec-bg-wave.png"
                  loading="lazy"
                  alt="Img"
                />
              </div>
              <div className="yacht-owner-title">
                <h3 className="primaryFont">
                  {t("leaveNoTrace.endSection.title")}
                </h3>
              </div>
              <div className="yacht-owner-img">
                <img
                  src="assets/img/bg/contact-us-about.png"
                  loading="lazy"
                  alt="Img"
                />
              </div>
            </div>
          </div>
        </section>
      </>
      {/* About us Testimonials */}
      {/* <Testimonials /> */}
      {/* About us Testimonials */}

      {/* CAARS */}
      <section className="section about-sec">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2 className="primaryFont">{t("aboutUs.carsSection.title")}</h2>
            {/* <p>{t("aboutUs.carsSection.subtitle")}</p> */}
          </div>
          <div className="row align-items-start text-center">
            {cars.map((car, index) => (
              <Col md={4} sm={6} className="text-center" key={index}>
                <img
                  src={car.image}
                  alt={car.label}
                  className="img-fluid rounded-3 my-3"
                />
                <h4 className="primaryFont mb-2">
                  {t(`cars.${car.label}.name`)}
                </h4>
                <p>{t(`cars.${car.label}.description`)}</p>
              </Col>
            ))}
          </div>
        </div>
      </section>
      {/* CAARS */}

      <section className="boats-marketplace-sec">
        <div className="container">
          <div className="yacht-owner-card">
            <div className="sec-bg">
              <img
                src="assets/img/bg/sec-bg-wave.png"
                loading="lazy"
                alt="Img"
              />
            </div>
            <div className="yacht-owner-title">
              <h3 className="primaryFont">
                {t("aboutUs.contactUsSection.title")}
              </h3>
              <p>{t("aboutUs.contactUsSection.subtitle")}</p>

              <Link to="/contact" className="btn btn-primary">
                {t("aboutUs.contactUsSection.buttonLabel")}
              </Link>
            </div>
            <div className="yacht-owner-img">
              <img
                src="assets/img/bg/contact-us-about.png"
                loading="lazy"
                alt="Img"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
