/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

const SignUp = ({
  toggle,
  goToLogin
}: {
  toggle: () => void;
  goToLogin: () => void;
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [dob, setDob] = useState("");

  const [agree, setAgree] = useState(false);

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState("");

  async function register() {
    setError("");
    setAttemptedSubmit(true);
    setSuccess(false);

    if (
      !name ||
      !email ||
      !password ||
      !password2 ||
      password !== password2 ||
      !/\S+@\S+\.\S+/.test(email) ||
      password.length < 6
    ) {
      return;
    }

    setIsSubmiting(true);

    const res = await SiteService.register(
      name,
      email,
      password,
      phone,
      dob
    ).catch((e) => {
      setError(e.message);
      return null;
    });
    setIsSubmiting(false);

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "sign_up"
    });

    if (!res || !res.token) {
      return;
    }

    localStorage.setItem("token", res.token);
    setSuccess(true);
  }

  const { t } = useTranslation();
  const benefits = t("benefits", { returnObjects: true }) as string[];
  return (
    <Modal show={true} className="modal-auth" onHide={toggle}>
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <h1 className="mb-0">{t("auth.signup.title")}</h1>
                <a className="close-btn-custom" onClick={toggle}>
                  x
                </a>
              </div>

              {/* /Social Login */}
              <div className="text-left dont-have">
                {t("auth.signup.alreadyMember")}{" "}
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                >
                  {t("auth.login.title")}
                </a>
              </div>

              <div className="listing-description">
                <div className="row review-sec listing-feature justify-content-between">
                  <div className="review-header">
                    <h4>{t("auth.signup.memberBenefitsLabel")} </h4>
                  </div>
                  {benefits.map((item, index) => (
                    <div className="col-md-5 p-0" key={index}>
                      <ul>
                        <li>
                          <span>
                            <i className="bx bx-check-double" />
                          </span>
                          {item}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  register();
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}
                {success && (
                  <Alert variant="success">
                    {t("auth.signup.successMessage")}
                  </Alert>
                )}
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.nameLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Name"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${!name && attemptedSubmit ? "d-block" : ""}`}
                  >
                    {t("auth.signup.nameErrorMessage")}
                  </div>
                </div>
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.phoneLabel")}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("auth.signup.phonePlaceholder")}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.dateOfBirthLabel")}{" "}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder={t("auth.signup.dateOfBirthPlaceholder")}
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.emailLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("auth.signup.emailPlaceholder")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      (!email || !/\S+@\S+\.\S+/.test(email)) && attemptedSubmit
                        ? "d-block"
                        : ""
                    }`}
                  >
                    {t("auth.signup.emailErrorMessage")}
                  </div>
                </div>

                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.passwordLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder={t("auth.signup.passwordPlaceholder")}
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      (!password || password.length < 6) && attemptedSubmit
                        ? "d-block"
                        : ""
                    }`}
                  >
                    {t("auth.signup.passwordErrorMessage")}
                  </div>
                </div>
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.signup.confirmPasswordLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>

                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder="Repeat Password"
                    value={password2}
                    required
                    onChange={(e) => setPassword2(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      password !== password2 && attemptedSubmit ? "d-block" : ""
                    }`}
                  >
                    {t("auth.signup.passwordErrorMessage")}
                  </div>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheck1"
                    dangerouslySetInnerHTML={{
                      __html: t("auth.signup.agreeTermsLabel")
                    }}
                  >
                    {/* {t("auth.signup.agreeTermsLabel")} */}
                    {/* Am citit, am inteles si sunt de acord cu <a href="/terms" target="_blank">Termenii si conditiile</a> si <a href="/privacy" target="_blank">Politica de confidentialitate</a>. */}
                  </label>
                </div>
                <button
                  className="btn btn-outline-light w-100 btn-size mt-1"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    if (!agree) {
                      alert(`${t("auth.signup.agreeTermsError")}`);
                      return;
                    }
                    register();
                  }}
                >
                  {t("auth.signup.signupButtonLabel")}{" "}
                  {isSubmiting ? (
                    <Spinner animation="border" size="sm" className="ms-1" />
                  ) : (
                    ""
                  )}
                </button>
              </form>
              {/* /Social Login */}
              <div className="text-center dont-have">
                {t("auth.signup.haveAccountMessage")}{" "}
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    goToLogin();
                  }}
                >
                  {t("auth.login.title")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SignUp;
