import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../../components/Breadcrumbs";

const TermsCondition = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/termeni-si-conditii`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/termeni-si-conditii`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/termeni-si-conditii?lang=en`}
        />
      </Helmet>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("termsAndConditionsPage.title")}`
          }
        ]}
        title={`${t("termsAndConditionsPage.title")}`}
      />
      <div className="main-wrapper">
        <div className="privacy-section">
          <div className="container">
            <h1>{t("termsAndConditionsPage.title")}</h1>

            <div className="row">
              <div className="col-lg-12">
                <div
                  className="terms-policy"
                  dangerouslySetInnerHTML={{
                    __html: t("termsAndConditionsPage.content")
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsCondition;
