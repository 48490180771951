import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminService, Faq as FaqModel } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "./Breadcrumbs";

export function Faq({ isHomePage = false }: { isHomePage?: boolean }) {
  const { t, i18n } = useTranslation();

  const [faqs, setFaqs] = useState<FaqModel[]>([]);

  useEffect(() => {
    async function fetchFaqs() {
      const faqs = await AdminService.getFaqs();
      setFaqs(faqs);
    }
    fetchFaqs();
  }, []);

  return (
    <>
      {!isHomePage && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ - Experienta off-road Romania -First Gear Club</title>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      {!isHomePage && (
        <Breadcrumbs
          items={[
            {
              link: `#`,
              name: `${t("home.faqSection.title")}`
            }
          ]}
          title={`${t("home.faqSection.title")}`}
        />
      )}
      <section className="section faq-section bg-light-primary" id="faq">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="primaryFont">{t("home.faqSection.title")}</h2>
            <p>{t("home.faqSection.subtitle")}</p>
          </div>
          {/* /Heading title */}
          <div className="faq-info">
            {faqs.map((faq) => (
              <div className="faq-card bg-white" key={faq.id}>
                <h3 className="faq-title">
                  <Link
                    className="collapsed"
                    data-bs-toggle="collapse"
                    to={`#faq${faq.id}`}
                    aria-expanded="false"
                  >
                    {i18n.language === "ro" ? faq.questionRo : faq.question}
                  </Link>
                </h3>
                <div id={`faq${faq.id}`} className="card-collapse collapse">
                  <p>{i18n.language === "ro" ? faq.answerRo : faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
