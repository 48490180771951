import React, { useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

const ResetPassword = ({
  toggle,
  goToLogin
}: {
  toggle: () => void;
  goToLogin: () => void;
}) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState("");

  async function resetPassword() {
    setError("");
    setAttemptedSubmit(true);
    setSuccess(false);

    if (
      !password ||
      !password2 ||
      password !== password2 ||
      password.length < 6
    ) {
      return;
    }

    setIsSubmiting(true);

    // get token query param
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token") || "";

    const res = await SiteService.resetPassword(token, password).catch((e) => {
      setError(e.message);
      return null;
    });
    setIsSubmiting(false);

    if (!res) {
      return;
    }

    setSuccess(true);
  }
  const { t } = useTranslation();
  return (
    <Modal show={true} className="modal-auth" onHide={toggle}>
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <h1 className="mb-0">{t("auth.resetPassword.title")}</h1>
                <a className="close-btn-custom" onClick={toggle}>
                  x
                </a>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}
                {success && (
                  <>
                    <Alert variant="success">
                      {t("auth.resetPassword.alertSuccess")}
                    </Alert>
                    <button
                      className="btn btn-outline-light w-100 btn-size mt-1"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        goToLogin();
                      }}
                    >
                      {t("auth.resetPassword.toLoginButtonLabel")}
                    </button>
                  </>
                )}
                {!success && (
                  <>
                    <div className="input-block">
                      <label className="form-label">
                        {t("auth.resetPassword.newPasswordLabel")}{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="password"
                        className="form-control pass-input"
                        placeholder="Your Password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className={`invalid-feedback ${
                          (!password || password.length < 6) && attemptedSubmit
                            ? "d-block"
                            : ""
                        }`}
                      >
                        {t("auth.resetPassword.newPasswordErrorMessage")}
                      </div>
                    </div>
                    <div className="input-block">
                      <label className="form-label">
                        {t("auth.resetPassword.confirmPasswordLabel")}{" "}
                        <span className="text-danger">*</span>
                      </label>

                      <input
                        type="password"
                        className="form-control pass-input"
                        placeholder={t(
                          "auth.resetPassword.confirmPasswordLabel"
                        )}
                        value={password2}
                        required
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                      <div
                        className={`invalid-feedback ${
                          password !== password2 && attemptedSubmit
                            ? "d-block"
                            : ""
                        }`}
                      >
                        {t("auth.resetPassword.confirmPasswordErrorMessage")}
                      </div>
                    </div>
                    <button
                      className="btn btn-outline-light w-100 btn-size mt-1"
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        resetPassword();
                      }}
                    >
                      {t("auth.resetPassword.submitButtonLabel")}
                      {isSubmiting ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-1"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassword;
