import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Footer */}
      <footer className="footer">
        {/* Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">
                        {t("footer.aboutCompanySection.title")}
                      </h5>
                      <ul>
                        <li>
                          <a href="/despre-noi">
                            {t("footer.aboutCompanySection.aboutUsLabel")}
                          </a>
                        </li>
                        <li>
                          <a href="/contact">
                            {t("footer.aboutCompanySection.contactLabel")}
                          </a>
                        </li>
                        <li>
                          <a href="/faq">
                            {t("footer.aboutCompanySection.faqLabel")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>

                  <div className="col-lg-6 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">
                        {t("footer.quickLinksSection.title")}
                      </h5>
                      <ul>
                        <li>
                          <a href="/termeni-si-conditii">
                            {t(
                              "footer.quickLinksSection.termsAndConditionsLabel"
                            )}
                          </a>
                        </li>
                        <li>
                          <a href="/politica-de-confidentialitate">
                            {t("footer.quickLinksSection.privacyPolicyLabel")}
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://anpc.ro/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("footer.quickLinksSection.anpcLabel")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="footer-contact footer-widget">
                  <h5 className="footer-title">
                    {t("footer.contactSection.title")}
                  </h5>
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <span>
                        <i className="feather icon-phone-call" />
                      </span>
                      <div className="addr-info">
                        <Link to="tel:+40744788888">+40 7447 88888</Link>
                      </div>
                    </div>
                    <div className="footer-address">
                      <span>
                        <i className="feather icon-mail" />
                      </span>
                      <div className="addr-info">
                        <Link to="mailto:hello@firstgearclub.com">
                          hello@firstgearclub.com
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="footer-social-widget">
                    <ul className="nav-social">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61563909583768"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Facebook"
                        >
                          <i className="fa-brands fa-facebook-f fa-facebook fi-icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/firstgearclub/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Instagram"
                        >
                          <i className="fab fa-instagram fi-icon" />
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://www.tiktok.com/@firstgearclub"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="TikTok"
                        >
                          <i className="fab fa-tiktok fi-icon" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://youtube.com/@1stgearclub"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Youtube"
                        >
                          <i className="fab fa-youtube fi-icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>
                      © {new Date().getFullYear()} First Gear Club. All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <div className="vistors-details">
                      <ul className="d-flex">
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/paypal.svg"
                              alt="Paypal"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/visa.svg"
                              alt="Visa"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/master.svg"
                              alt="Master"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/applegpay.svg"
                              alt="applegpay"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
      {/* /Footer */}
    </>
  );
};

export default Footer;
