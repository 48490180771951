import React, { useEffect, useRef } from "react";

export const QuizTraseeSalbatice = () => {
  const scriptLoadedRef = useRef(false); // Track if the script has already been loaded
  useEffect(() => {
    if (scriptLoadedRef.current) return; // Prevent adding the script twice

    // Create the script element
    const script = document.createElement("script");
    script.src =
      "https://storycards.co/digital-data-capital/d3YyVBRDt/sdk-prod.js?ver=09ccdbe3-423d-41ef-8614-e346ae9ce68b-0";
    script.async = true;
    script.dataset.id = "digital-data-capital/d3YyVBRDt";

    // Add the onload function to embed the story
    script.onload = () => {
      if (window.embedStory) {
        window.embedStory("digital-data-capital/d3YyVBRDt", {
          domain: "https://storycards.co/digital-data-capital/d3YyVBRDt/"
        });
      }
    };

    // Append the script to the body
    document.body.appendChild(script);
    scriptLoadedRef.current = true;

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures it runs only once

  return (
    <div
      id="story-digital-data-capital/d3YyVBRDt"
      style={{ width: "100vw", height: "100vh" }}
    ></div>
  );
};
