import React from "react";
import { Helmet } from "react-helmet";
// import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../../components/Breadcrumbs";
const CheckoutFail = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/checkout-fail`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/checkout-fail`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/checkout-fail?lang=en`}
        />
      </Helmet>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: "Checkout Fail"
          }
        ]}
        title={`Checkout Fail`}
      />
      {/* Booking Success */}
      <div className="booking-new-module">
        <div className="container">
          {/* <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("checkout.reserveYourTourHeaderLabel")
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-02.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.addonsLabel")}</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-03.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.detailsLabel")}</h6>
                    </li>
                    <li>
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-04.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.checkoutLabel")}</h6>
                    </li>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-05.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.checkoutFail.bookingFailedLabel")}</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="booking-card">
            <div className="success-book">
              <span className="success-icon bg-danger">
                <i className="fa-solid fa-check-double" />
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("checkout.checkoutFail.bookingFailedMessage")
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* /Booking Success */}
    </div>
  );
};

export default CheckoutFail;
