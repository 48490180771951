import React, { useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

const Login = ({
  toggle,
  goToSignup,
  goToForgotPassword
}: {
  toggle: () => void;
  goToSignup: () => void;
  goToForgotPassword: () => void;
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const [error, setError] = useState("");

  async function login() {
    setError("");
    setAttemptedSubmit(true);

    if (!email || !password || !/\S+@\S+\.\S+/.test(email)) {
      return;
    }

    setIsSubmiting(true);
    const res = await SiteService.login(email, password).catch((e) => {
      setError(e.message);
      return null;
    });
    setIsSubmiting(false);

    if (!res || !res.token) {
      return;
    }

    localStorage.setItem("token", res.token);
    toggle();
  }
  const { t } = useTranslation();
  const benefits = t("benefits", { returnObjects: true }) as string[];
  return (
    <Modal show={true} className="modal-auth" onHide={toggle}>
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <h1 className="mb-0">{t("auth.login.title")}</h1>
                <a className="close-btn-custom" onClick={toggle}>
                  x
                </a>
              </div>
              <div className="listing-description">
                <div className="row review-sec listing-feature justify-content-between">
                  <div className="review-header">
                    <h4>{t("auth.login.memberBenefitsLabel")}</h4>
                  </div>
                  {benefits.map((item, index) => (
                    <div className="col-md-5 p-0" key={index}>
                      <ul>
                        <li>
                          <span>
                            <i className="bx bx-check-double" />
                          </span>
                          {item}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  login();
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.login.emailLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      (!email || !/\S+@\S+\.\S+/.test(email)) && attemptedSubmit
                        ? "d-block"
                        : ""
                    }`}
                  >
                    {t("auth.login.emailErrorMessage")}
                  </div>
                </div>
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.login.passwordLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    className="form-control pass-input"
                    placeholder="Your Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      !password && attemptedSubmit ? "d-block" : ""
                    }`}
                  >
                    {t("auth.login.passwordErrorMessage")}
                  </div>
                </div>
                <div className="input-block">
                  <a
                    className="forgot-link"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                      goToForgotPassword();
                    }}
                  >
                    {t("auth.login.forgotPasswordLabel")}
                  </a>
                </div>
                <button
                  className="btn btn-outline-light w-100 btn-size mt-1"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                >
                  Login
                  {isSubmiting ? (
                    <Spinner animation="border" size="sm" className="ms-1" />
                  ) : (
                    ""
                  )}
                </button>

                {/* /Social Login */}
                <div className="text-center dont-have">
                  {t("auth.login.dontHaveAccountLabel")}{" "}
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      goToSignup();
                    }}
                  >
                    {t("auth.login.signupLabel")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Login;
