import React, { useState } from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

const ForgotPassword = ({
  toggle,
  goToSignup
}: {
  toggle: () => void;
  goToSignup: () => void;
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  async function forgotPassword() {
    setError("");
    setAttemptedSubmit(true);

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      return;
    }

    setIsSubmiting(true);
    const res = await SiteService.forgotPassword(email).catch((e) => {
      setError(e.message);
      return null;
    });
    setIsSubmiting(false);

    if (!res) {
      return;
    }

    setSuccess(true);
  }

  return (
    <Modal show={true} className="modal-auth" onHide={toggle}>
      <div className="login-wrapper">
        <div className="loginbox">
          <div className="login-auth">
            <div className="login-auth-wrap">
              <div className="w-100 d-flex justify-content-between align-items-center mb-2">
                <h1 className="mb-0">{t("auth.forgotPassword.title")}</h1>
                <a className="close-btn-custom" onClick={toggle}>
                  x
                </a>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  forgotPassword();
                }}
              >
                {error && <Alert variant="danger">{error}</Alert>}
                {success && (
                  <Alert variant="success">
                    {t("auth.forgotPassword.alertMessage")}
                  </Alert>
                )}
                <div className="input-block">
                  <label className="form-label">
                    {t("auth.forgotPassword.emailLabel")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className={`invalid-feedback ${
                      (!email || !/\S+@\S+\.\S+/.test(email)) && attemptedSubmit
                        ? "d-block"
                        : ""
                    }`}
                  >
                    {t("auth.forgotPassword.emailErrorLabel")}
                  </div>
                </div>

                <button
                  className="btn btn-outline-light w-100 btn-size mt-1"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    forgotPassword();
                  }}
                >
                  {t("auth.forgotPassword.sendButtonLabel")}
                  {isSubmiting ? (
                    <Spinner animation="border" size="sm" className="ms-1" />
                  ) : (
                    ""
                  )}
                </button>

                {/* /Social Login */}
                <div className="text-center dont-have">
                  {t("auth.forgotPassword.dontHaveAccountMessage")}{" "}
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      goToSignup();
                    }}
                  >
                    {t("auth.forgotPassword.signupLabel")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPassword;
