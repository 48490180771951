import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AllRoutes from "./feature-module/router/router";
import { set_is_mobile_sidebar } from "./core/data/redux/action";

const Feature = () => {
  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_is_mobile_sidebar(false));
  }, [location, dispatch]);

  return (
    <>
      <div
        className={`main-wrapper ${mobileSidebar ? "menu-opened true" : "false"}`}
      >
        <AllRoutes />
      </div>
    </>
  );
};

export default Feature;
