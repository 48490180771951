import React from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./LandingPageNewsletter.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const LandingPageNewsletter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <section className="banner-section landing-banner-slider">
        <div className="home-banner">
          <Container fluid>
            <div className="row align-items-start">
              <div className="col-lg-6 banner-text p-4">
                <h1 className="primaryFont">{t("landingNewsletter.banner")}</h1>
                <p className="mt-3">{t("landingNewsletter.subtitle")}</p>
              </div>
            </div>
          </Container>
        </div>
      </section>
      <Container>
        <Row className="my-4">
          <div className="col-md-4 col-12 d-flex justify-content-center my-2">
            <div className="benefit-cards p-3 d-flex align-items-center">
              <div className="count-img">
                <ImageWithBasePath
                  src="assets/img/landing-mountains.svg"
                  alt=""
                  height={98}
                  width={104}
                />
              </div>
              <div className="ms-3">
                <h2 className="mb-2">
                  {" "}
                  {t("landingNewsletter.benefits.authentic_adventure_title")}
                </h2>
                <p>{t("landingNewsletter.benefits.authentic_adventure")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 d-flex justify-content-center my-2">
            <div className="benefit-cards p-3 d-flex align-items-center">
              <div className="count-img">
                <ImageWithBasePath
                  src="assets/img/landing-stars.svg"
                  alt=""
                  height={98}
                  width={104}
                />
              </div>
              <div className="ms-3">
                <h2 className="mb-2">
                  {t("landingNewsletter.benefits.exclusive_content_title")}
                </h2>
                <p>{t("landingNewsletter.benefits.exclusive_content")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 d-flex justify-content-center my-2">
            <div className="benefit-cards p-3 d-flex align-items-center">
              <div className="count-img">
                <ImageWithBasePath
                  src="assets/img/landing-present.svg"
                  alt=""
                  height={98}
                  width={104}
                />
              </div>
              <div className="ms-3">
                <h2 className="mb-2">
                  {t("landingNewsletter.benefits.gift_voucher_title")}
                </h2>
                <p>{t("landingNewsletter.benefits.gift_voucher")}</p>
              </div>
            </div>
          </div>
        </Row>
        <Row className="my-4">
          <Container>
            <Row className="d-flex justify-content-between">
              <Col
                xs={12}
                md={6}
                className="d-flex justify-content-start flex-column pe-xl-2"
              >
                <div className="text-center">
                  <h3>{t("landingNewsletter.call_to_action_text")}</h3>
                  <p className="mt-3">
                    {t("landingNewsletter.subscribe_text")}
                  </p>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    className="landing-button fw-bold"
                    onClick={() => {
                      navigate("/newsletter?popup=signup");
                    }}
                  >
                    {t("landingNewsletter.call_to_action")}
                  </Button>
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className="d-flex flex-column justify-content-center align-items-center ps-xl-5"
              >
                <h3>{t("landingNewsletter.video_section")}</h3>
                <iframe
                  className="mt-3"
                  src="https://www.youtube.com/embed/NT2FdWFRJto"
                  width={100}
                  height={350}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default LandingPageNewsletter;
