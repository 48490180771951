/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { ContactUs } from "../../core/data/interface/interface";
import { useTranslation } from "react-i18next";
import { ContactForm } from "../../components/ContactForm";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";
export const contactData = [
  {
    type: "phone",
    icon: "feather icon-phone-call",
    title: "Phone Number",
    link: "tel:+40744788888",
    text: "+40 7447 88888"
  },
  {
    type: "email",
    icon: "feather icon-mail",
    title: "Email Address",
    link: "mailto:hello@firstgearclub.com",
    text: "hello@firstgearclub.com"
  },
  {
    type: "hours",
    icon: "feather icon-clock",
    title: "Opening Hours",
    link: "#",
    text: "24/7 Phone Support"
  }
];

const Contact = () => {
  const { t } = useTranslation();
  const translationData = t("contact.contactData", { returnObjects: true }) as {
    title: string;
    link: string;
    text: string;
  }[];
  const newContactData = contactData.map((contact, index) => {
    return {
      ...contact,
      title: translationData[index].title,
      text: translationData[index].text,
      link: translationData[index].link
    };
  });

  return (
    <>
      {localStorage.getItem("i18nextLng") === "ro" ? (
        <Helmet>
          <link rel="canonical" href={`https://firstgearclub.com/contact`} />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/contact`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/contact?lang=en`}
          />

          <title>Contacteaza First Gear Club | Asistenta Telefonica 24/7</title>
          <meta
            name="description"
            content="Contacteaza First Gear Club pentru intrebari sau rezervari de tururi off-road. Asistenta telefonica 24/7 sau trimite-ne un e-mail ⭐ Suntem aici sa te ajutam!"
          />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="canonical" href={`https://firstgearclub.com/contact`} />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/contact`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/contact?lang=en`}
          />

          <title>Contact First Gear Club | 24/7 Phone Support</title>
          <meta
            name="description"
            content="Contact First Gear Club for inquiries or off-road tour bookings. 24/7 phone support or send us an email ⭐ We're here to help!"
          />
        </Helmet>
      )}
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: "Contact"
          }
        ]}
        title={`Contact`}
      />
      <div className="main-wrapper">
        <section className="contact-section">
          <div className="container">
            <div className="form-info-area mb-3">
              <div className="row">
                <div className="col-lg-6 d-flex">
                  <ImageWithBasePath
                    src="assets/img/contact-info.jpg"
                    className="img-fluid"
                    alt="Contact"
                  />
                </div>
                <div className="col-lg-6">
                  <h1 className="primaryFont">{t("contact.title")}</h1>

                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="contact-info-area">
              <div className="row">
                {newContactData.map((info: ContactUs, index: number) => (
                  <div key={index} className="col-lg-4 col-md-6 col-12 d-flex">
                    <div className="single-contact-info flex-fill">
                      <span>
                        <i className={info.icon} />
                      </span>
                      <h3>{info.title}</h3>
                      {info.type === "phone" ? (
                        <Link to={info.link}>{info.text}</Link>
                      ) : (
                        <p>
                          <Link to={info.link}>{info.text}</Link>
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Contact;
