export const benefits = [
  "5% discount for all bookings",
  "25 Euro Birthday Reward",
  "25 Euro Referral Reward",
  "Faster Check-In",
  "Travel Companion Program",
  "Club Upgrades"
];

export const articleStructuredData = {
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "First Gear Club",
  url: "https://firstgearclub.com",
  logo: "https://firstgearclub.com/assets/img/logo.svg",
  sameAs: [
    "https://www.facebook.com/FirstGearClub",
    "https://www.instagram.com/FirstGearClub",
    "https://www.tiktok.com/@firstgearclub",
    "https://www.youtube.com/@1stgearclub"
  ],
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: "+40 7447 88888",
      contactType: "customer service",
      areaServed: "Romania"
    }
  ]
};
