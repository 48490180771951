/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
// import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { AdminService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";

const CheckoutSuccess = () => {
  const { t } = useTranslation();

  React.useEffect(() => {
    async function asyncFn() {
      // get ? booking_id
      const urlParams = new URLSearchParams(window.location.search);
      const bookingId = urlParams.get("booking_id");
      const voucher = urlParams.get("voucher");

      if (
        voucher &&
        !Number.isNaN(parseInt(voucher)) &&
        [300, 500].includes(parseInt(voucher))
      ) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        dataLayer.push({
          event: "gift_voucher",
          value: parseInt(voucher),
          currency: "EUR"
        });
      }

      if (!bookingId) {
        return;
      }

      // get booking details
      const bookingObj = await AdminService.getBookingById(bookingId).catch(
        (err) => {
          console.error(err);
          return null;
        }
      );

      if (!bookingObj) {
        return;
      }

      let totalPrice = bookingObj.booking.extras.reduce(
        (acc, extra) => acc + (extra.price || 0),
        bookingObj.booking.package.price
      );

      if (bookingObj.discount) {
        // discount is in percentage
        totalPrice -= totalPrice * (bookingObj.discount / 100);
      }

      // @ts-ignore
      window.dataLayer = window.dataLayer || [];

      // @ts-ignore
      window.dataLayer.push({ items: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "purchase",
        transaction_id: bookingId,
        currency: "EUR",
        value: totalPrice - totalPrice * bookingObj.discount, //valoarea pe care userul trebuie sa o achite
        tax: 0,
        shipping: 0,
        coupon: bookingObj.coupon,
        discount: totalPrice * bookingObj.discount,
        order_status: bookingObj.booking.status,
        items: [
          {
            item_id: bookingObj.booking.tourId,
            item_name: bookingObj.tour.name,
            item_category: bookingObj.tour.startLocation,
            item_variant: bookingObj.booking.package.name,
            price: bookingObj.booking.package.price,
            quantity: 1,
            index: 0
          },
          ...bookingObj.booking.extras.map((extra) => ({
            item_id: extra.id,
            item_name: extra.name,
            item_category: "",
            item_variant: extra.name,
            price: extra.price,
            quantity: 1,
            index: 0
          }))
        ]
      });
    }

    asyncFn();
  }, []);

  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/checkout-success`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/checkout-success`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/checkout-success?lang=en`}
        />
      </Helmet>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: "Checkout Success"
          }
        ]}
        title={`Checkout Success`}
      />
      {/* Booking Success */}
      <div className="booking-new-module">
        <div className="container">
          {/* <div className="booking-wizard-head">
            <div className="row align-items-center">
              <div className="col-xl-4 col-lg-3">
                <div className="booking-head-title">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("checkout.reserveYourTourHeaderLabel")
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="booking-wizard-lists">
                  <ul>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-02.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.addonsLabel")}</h6>
                    </li>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-03.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.detailsLabel")}</h6>
                    </li>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-04.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>{t("checkout.checkoutLabel")}</h6>
                    </li>
                    <li className="active">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/booking-head-icon-05.svg"
                          alt="Booking Icon"
                        />
                      </span>
                      <h6>
                        {t("checkout.checkoutSuccess.bookingSuccessLabel")}
                      </h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
          <div className="booking-card">
            <div className="success-book">
              <span className="success-icon">
                <i className="fa-solid fa-check-double" />
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("checkout.checkoutSuccess.bookingSuccessMessage")
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* /Booking Success */}
    </div>
  );
};

export default CheckoutSuccess;
