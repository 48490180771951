import React, { useEffect } from "react";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const EmailVerified = () => {
  const [verified, setVerified] = React.useState(false);
  useEffect(() => {
    async function verifyEmail() {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");

      if (!id) {
        return;
      }

      const res = await SiteService.verifyEmail(id).catch((e) => {
        console.error(e);
        return null;
      });

      if (!res) {
        return;
      }
      setVerified(true);
    }

    verifyEmail();
  }, []);
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/email-verified`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/email-verified`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/email-verified?lang=en`}
        />
      </Helmet>
      {/* Booking Success */}
      <div className="booking-new-module">
        <div className="container">
          <div className="booking-card">
            <div className="success-book">
              <span className="success-icon">
                <i className="fa-solid fa-check-double" />
              </span>
              <h5>{t("auth.emailVerified.title")}</h5>
              <h5 className="order-no">
                {verified ? (
                  t("auth.emailVerified.message")
                ) : (
                  <Spinner animation="border" />
                )}
              </h5>
              <Button href="/" className="btn btn-primary mt-4">
                {t("auth.emailVerified.goToWebsiteButtonLabel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* /Booking Success */}
    </div>
  );
};

export default EmailVerified;
