import React from "react";
import { useTranslation } from "react-i18next";

export function Breadcrumbs({
  items,
  title
}: {
  items: { link: string; name: string }[];
  title: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="breadcrumb-bar">
      <div className="container">
        <div className="row align-items-center text-center">
          <div className="col-md-12 col-12">
            <h2 className="breadcrumb-title">{title}</h2>
            <nav aria-label="breadcrumb" className="page-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">{t("header.navLinks.home")}</a>
                </li>
                {items.map((item, index) => (
                  <li
                    className={`breadcrumb-item active ${
                      index === items.length - 1 ? "active" : ""
                    }`}
                    key={index}
                    aria-current="page"
                  >
                    {index === items.length - 1 ? (
                      <>{item.name}</>
                    ) : (
                      <a href={item.link}>{item.name}</a>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
