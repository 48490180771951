export const countryPhonePrefixes = [
  { country: "Afghanistan", prefix: "+93", flag: "https://flagcdn.com/af.svg" },
  { country: "Albania", prefix: "+355", flag: "https://flagcdn.com/al.svg" },
  { country: "Algeria", prefix: "+213", flag: "https://flagcdn.com/dz.svg" },
  { country: "Andorra", prefix: "+376", flag: "https://flagcdn.com/ad.svg" },
  { country: "Angola", prefix: "+244", flag: "https://flagcdn.com/ao.svg" },
  {
    country: "Antigua and Barbuda",
    prefix: "+1-268",
    flag: "https://flagcdn.com/ag.svg"
  },
  { country: "Argentina", prefix: "+54", flag: "https://flagcdn.com/ar.svg" },
  { country: "Armenia", prefix: "+374", flag: "https://flagcdn.com/am.svg" },
  { country: "Australia", prefix: "+61", flag: "https://flagcdn.com/au.svg" },
  { country: "Austria", prefix: "+43", flag: "https://flagcdn.com/at.svg" },
  { country: "Azerbaijan", prefix: "+994", flag: "https://flagcdn.com/az.svg" },
  { country: "Bahamas", prefix: "+1-242", flag: "https://flagcdn.com/bs.svg" },
  { country: "Bahrain", prefix: "+973", flag: "https://flagcdn.com/bh.svg" },
  { country: "Bangladesh", prefix: "+880", flag: "https://flagcdn.com/bd.svg" },
  { country: "Barbados", prefix: "+1-246", flag: "https://flagcdn.com/bb.svg" },
  { country: "Belarus", prefix: "+375", flag: "https://flagcdn.com/by.svg" },
  { country: "Belgium", prefix: "+32", flag: "https://flagcdn.com/be.svg" },
  { country: "Belize", prefix: "+501", flag: "https://flagcdn.com/bz.svg" },
  { country: "Benin", prefix: "+229", flag: "https://flagcdn.com/bj.svg" },
  { country: "Bhutan", prefix: "+975", flag: "https://flagcdn.com/bt.svg" },
  { country: "Bolivia", prefix: "+591", flag: "https://flagcdn.com/bo.svg" },
  {
    country: "Bosnia and Herzegovina",
    prefix: "+387",
    flag: "https://flagcdn.com/ba.svg"
  },
  { country: "Botswana", prefix: "+267", flag: "https://flagcdn.com/bw.svg" },
  { country: "Brazil", prefix: "+55", flag: "https://flagcdn.com/br.svg" },
  { country: "Brunei", prefix: "+673", flag: "https://flagcdn.com/bn.svg" },
  { country: "Bulgaria", prefix: "+359", flag: "https://flagcdn.com/bg.svg" },
  {
    country: "Burkina Faso",
    prefix: "+226",
    flag: "https://flagcdn.com/bf.svg"
  },
  { country: "Burundi", prefix: "+257", flag: "https://flagcdn.com/bi.svg" },
  { country: "Cabo Verde", prefix: "+238", flag: "https://flagcdn.com/cv.svg" },
  { country: "Cambodia", prefix: "+855", flag: "https://flagcdn.com/kh.svg" },
  { country: "Cameroon", prefix: "+237", flag: "https://flagcdn.com/cm.svg" },
  { country: "Canada", prefix: "+1", flag: "https://flagcdn.com/ca.svg" },
  {
    country: "Central African Republic",
    prefix: "+236",
    flag: "https://flagcdn.com/cf.svg"
  },
  { country: "Chad", prefix: "+235", flag: "https://flagcdn.com/td.svg" },
  { country: "Chile", prefix: "+56", flag: "https://flagcdn.com/cl.svg" },
  { country: "China", prefix: "+86", flag: "https://flagcdn.com/cn.svg" },
  { country: "Colombia", prefix: "+57", flag: "https://flagcdn.com/co.svg" },
  { country: "Comoros", prefix: "+269", flag: "https://flagcdn.com/km.svg" },
  { country: "Congo", prefix: "+242", flag: "https://flagcdn.com/cg.svg" },
  { country: "Costa Rica", prefix: "+506", flag: "https://flagcdn.com/cr.svg" },
  { country: "Croatia", prefix: "+385", flag: "https://flagcdn.com/hr.svg" },
  { country: "Cuba", prefix: "+53", flag: "https://flagcdn.com/cu.svg" },
  { country: "Cyprus", prefix: "+357", flag: "https://flagcdn.com/cy.svg" },
  {
    country: "Czech Republic",
    prefix: "+420",
    flag: "https://flagcdn.com/cz.svg"
  },
  { country: "Denmark", prefix: "+45", flag: "https://flagcdn.com/dk.svg" },
  { country: "Djibouti", prefix: "+253", flag: "https://flagcdn.com/dj.svg" },
  { country: "Dominica", prefix: "+1-767", flag: "https://flagcdn.com/dm.svg" },
  {
    country: "Dominican Republic",
    prefix: "+1-809",
    flag: "https://flagcdn.com/do.svg"
  },
  { country: "Ecuador", prefix: "+593", flag: "https://flagcdn.com/ec.svg" },
  { country: "Egypt", prefix: "+20", flag: "https://flagcdn.com/eg.svg" },
  {
    country: "El Salvador",
    prefix: "+503",
    flag: "https://flagcdn.com/sv.svg"
  },
  {
    country: "Equatorial Guinea",
    prefix: "+240",
    flag: "https://flagcdn.com/gq.svg"
  },
  { country: "Eritrea", prefix: "+291", flag: "https://flagcdn.com/er.svg" },
  { country: "Estonia", prefix: "+372", flag: "https://flagcdn.com/ee.svg" },
  { country: "Eswatini", prefix: "+268", flag: "https://flagcdn.com/sz.svg" },
  { country: "Ethiopia", prefix: "+251", flag: "https://flagcdn.com/et.svg" },
  { country: "Fiji", prefix: "+679", flag: "https://flagcdn.com/fj.svg" },
  { country: "Finland", prefix: "+358", flag: "https://flagcdn.com/fi.svg" },
  { country: "France", prefix: "+33", flag: "https://flagcdn.com/fr.svg" },
  { country: "Gabon", prefix: "+241", flag: "https://flagcdn.com/ga.svg" },
  { country: "Gambia", prefix: "+220", flag: "https://flagcdn.com/gm.svg" },
  { country: "Georgia", prefix: "+995", flag: "https://flagcdn.com/ge.svg" },
  { country: "Germany", prefix: "+49", flag: "https://flagcdn.com/de.svg" },
  { country: "Ghana", prefix: "+233", flag: "https://flagcdn.com/gh.svg" },
  { country: "Greece", prefix: "+30", flag: "https://flagcdn.com/gr.svg" },
  { country: "Grenada", prefix: "+1-473", flag: "https://flagcdn.com/gd.svg" },
  { country: "Guatemala", prefix: "+502", flag: "https://flagcdn.com/gt.svg" },
  { country: "Guinea", prefix: "+224", flag: "https://flagcdn.com/gn.svg" },
  {
    country: "Guinea-Bissau",
    prefix: "+245",
    flag: "https://flagcdn.com/gw.svg"
  },
  { country: "Guyana", prefix: "+592", flag: "https://flagcdn.com/gy.svg" },
  { country: "Haiti", prefix: "+509", flag: "https://flagcdn.com/ht.svg" },
  { country: "Honduras", prefix: "+504", flag: "https://flagcdn.com/hn.svg" },
  { country: "Hungary", prefix: "+36", flag: "https://flagcdn.com/hu.svg" },
  { country: "Iceland", prefix: "+354", flag: "https://flagcdn.com/is.svg" },
  { country: "India", prefix: "+91", flag: "https://flagcdn.com/in.svg" },
  { country: "Indonesia", prefix: "+62", flag: "https://flagcdn.com/id.svg" },
  { country: "Iran", prefix: "+98", flag: "https://flagcdn.com/ir.svg" },
  {
    country: "Iraq",
    prefix: "+964",
    flag: "https://flagcdn.com/iq.svg"
  },
  { country: "Ireland", prefix: "+353", flag: "https://flagcdn.com/ie.svg" },
  { country: "Israel", prefix: "+972", flag: "https://flagcdn.com/il.svg" },
  { country: "Italy", prefix: "+39", flag: "https://flagcdn.com/it.svg" },
  { country: "Jamaica", prefix: "+1-876", flag: "https://flagcdn.com/jm.svg" },
  { country: "Japan", prefix: "+81", flag: "https://flagcdn.com/jp.svg" },
  { country: "Jordan", prefix: "+962", flag: "https://flagcdn.com/jo.svg" },
  { country: "Kazakhstan", prefix: "+7", flag: "https://flagcdn.com/kz.svg" },
  { country: "Kenya", prefix: "+254", flag: "https://flagcdn.com/ke.svg" },
  { country: "Kiribati", prefix: "+686", flag: "https://flagcdn.com/ki.svg" },
  { country: "Kuwait", prefix: "+965", flag: "https://flagcdn.com/kw.svg" },
  { country: "Kyrgyzstan", prefix: "+996", flag: "https://flagcdn.com/kg.svg" },
  { country: "Laos", prefix: "+856", flag: "https://flagcdn.com/la.svg" },
  { country: "Latvia", prefix: "+371", flag: "https://flagcdn.com/lv.svg" },
  { country: "Lebanon", prefix: "+961", flag: "https://flagcdn.com/lb.svg" },
  { country: "Lesotho", prefix: "+266", flag: "https://flagcdn.com/ls.svg" },
  { country: "Liberia", prefix: "+231", flag: "https://flagcdn.com/lr.svg" },
  { country: "Libya", prefix: "+218", flag: "https://flagcdn.com/ly.svg" },
  {
    country: "Liechtenstein",
    prefix: "+423",
    flag: "https://flagcdn.com/li.svg"
  },
  { country: "Lithuania", prefix: "+370", flag: "https://flagcdn.com/lt.svg" },
  { country: "Luxembourg", prefix: "+352", flag: "https://flagcdn.com/lu.svg" },
  { country: "Madagascar", prefix: "+261", flag: "https://flagcdn.com/mg.svg" },
  { country: "Malawi", prefix: "+265", flag: "https://flagcdn.com/mw.svg" },
  { country: "Malaysia", prefix: "+60", flag: "https://flagcdn.com/my.svg" },
  { country: "Maldives", prefix: "+960", flag: "https://flagcdn.com/mv.svg" },
  { country: "Mali", prefix: "+223", flag: "https://flagcdn.com/ml.svg" },
  { country: "Malta", prefix: "+356", flag: "https://flagcdn.com/mt.svg" },
  {
    country: "Marshall Islands",
    prefix: "+692",
    flag: "https://flagcdn.com/mh.svg"
  },
  { country: "Mauritania", prefix: "+222", flag: "https://flagcdn.com/mr.svg" },
  { country: "Mauritius", prefix: "+230", flag: "https://flagcdn.com/mu.svg" },
  { country: "Mexico", prefix: "+52", flag: "https://flagcdn.com/mx.svg" },
  { country: "Micronesia", prefix: "+691", flag: "https://flagcdn.com/fm.svg" },
  { country: "Moldova", prefix: "+373", flag: "https://flagcdn.com/md.svg" },
  { country: "Monaco", prefix: "+377", flag: "https://flagcdn.com/mc.svg" },
  { country: "Mongolia", prefix: "+976", flag: "https://flagcdn.com/mn.svg" },
  { country: "Montenegro", prefix: "+382", flag: "https://flagcdn.com/me.svg" },
  { country: "Morocco", prefix: "+212", flag: "https://flagcdn.com/ma.svg" },
  { country: "Mozambique", prefix: "+258", flag: "https://flagcdn.com/mz.svg" },
  { country: "Myanmar", prefix: "+95", flag: "https://flagcdn.com/mm.svg" },
  { country: "Namibia", prefix: "+264", flag: "https://flagcdn.com/na.svg" },
  { country: "Nauru", prefix: "+674", flag: "https://flagcdn.com/nr.svg" },
  { country: "Nepal", prefix: "+977", flag: "https://flagcdn.com/np.svg" },
  { country: "Netherlands", prefix: "+31", flag: "https://flagcdn.com/nl.svg" },
  { country: "New Zealand", prefix: "+64", flag: "https://flagcdn.com/nz.svg" },
  { country: "Nicaragua", prefix: "+505", flag: "https://flagcdn.com/ni.svg" },
  { country: "Niger", prefix: "+227", flag: "https://flagcdn.com/ne.svg" },
  { country: "Nigeria", prefix: "+234", flag: "https://flagcdn.com/ng.svg" },
  {
    country: "North Korea",
    prefix: "+850",
    flag: "https://flagcdn.com/kp.svg"
  },
  {
    country: "North Macedonia",
    prefix: "+389",
    flag: "https://flagcdn.com/mk.svg"
  },
  { country: "Norway", prefix: "+47", flag: "https://flagcdn.com/no.svg" },
  { country: "Oman", prefix: "+968", flag: "https://flagcdn.com/om.svg" },
  { country: "Pakistan", prefix: "+92", flag: "https://flagcdn.com/pk.svg" },
  { country: "Palau", prefix: "+680", flag: "https://flagcdn.com/pw.svg" },
  { country: "Panama", prefix: "+507", flag: "https://flagcdn.com/pa.svg" },
  {
    country: "Papua New Guinea",
    prefix: "+675",
    flag: "https://flagcdn.com/pg.svg"
  },
  { country: "Paraguay", prefix: "+595", flag: "https://flagcdn.com/py.svg" },
  { country: "Peru", prefix: "+51", flag: "https://flagcdn.com/pe.svg" },
  { country: "Philippines", prefix: "+63", flag: "https://flagcdn.com/ph.svg" },
  { country: "Poland", prefix: "+48", flag: "https://flagcdn.com/pl.svg" },
  { country: "Portugal", prefix: "+351", flag: "https://flagcdn.com/pt.svg" },
  { country: "Qatar", prefix: "+974", flag: "https://flagcdn.com/qa.svg" },
  { country: "Romania", prefix: "+40", flag: "https://flagcdn.com/ro.svg" },
  { country: "Russia", prefix: "+7", flag: "https://flagcdn.com/ru.svg" },
  { country: "Rwanda", prefix: "+250", flag: "https://flagcdn.com/rw.svg" },
  {
    country: "Saint Kitts and Nevis",
    prefix: "+1-869",
    flag: "https://flagcdn.com/kn.svg"
  },
  {
    country: "Saint Lucia",
    prefix: "+1-758",
    flag: "https://flagcdn.com/lc.svg"
  },
  {
    country: "Saint Vincent and the Grenadines",
    prefix: "+1-784",
    flag: "https://flagcdn.com/vc.svg"
  },
  { country: "Samoa", prefix: "+685", flag: "https://flagcdn.com/ws.svg" },
  { country: "San Marino", prefix: "+378", flag: "https://flagcdn.com/sm.svg" },
  {
    country: "Sao Tome and Principe",
    prefix: "+239",
    flag: "https://flagcdn.com/st.svg"
  },
  {
    country: "Saudi Arabia",
    prefix: "+966",
    flag: "https://flagcdn.com/sa.svg"
  },
  { country: "Senegal", prefix: "+221", flag: "https://flagcdn.com/sn.svg" },
  { country: "Serbia", prefix: "+381", flag: "https://flagcdn.com/rs.svg" },
  { country: "Seychelles", prefix: "+248", flag: "https://flagcdn.com/sc.svg" },
  {
    country: "Sierra Leone",
    prefix: "+232",
    flag: "https://flagcdn.com/sl.svg"
  },
  { country: "Singapore", prefix: "+65", flag: "https://flagcdn.com/sg.svg" },
  { country: "Slovakia", prefix: "+421", flag: "https://flagcdn.com/sk.svg" },
  { country: "Slovenia", prefix: "+386", flag: "https://flagcdn.com/si.svg" },
  {
    country: "Solomon Islands",
    prefix: "+677",
    flag: "https://flagcdn.com/sb.svg"
  },
  { country: "Somalia", prefix: "+252", flag: "https://flagcdn.com/so.svg" },
  {
    country: "South Africa",
    prefix: "+27",
    flag: "https://flagcdn.com/za.svg"
  },
  { country: "South Korea", prefix: "+82", flag: "https://flagcdn.com/kr.svg" },
  {
    country: "South Sudan",
    prefix: "+211",
    flag: "https://flagcdn.com/ss.svg"
  },
  { country: "Spain", prefix: "+34", flag: "https://flagcdn.com/es.svg" },
  { country: "Sri Lanka", prefix: "+94", flag: "https://flagcdn.com/lk.svg" },
  { country: "Sudan", prefix: "+249", flag: "https://flagcdn.com/sd.svg" },
  { country: "Suriname", prefix: "+597", flag: "https://flagcdn.com/sr.svg" },
  { country: "Sweden", prefix: "+46", flag: "https://flagcdn.com/se.svg" },
  { country: "Switzerland", prefix: "+41", flag: "https://flagcdn.com/ch.svg" },
  { country: "Syria", prefix: "+963", flag: "https://flagcdn.com/sy.svg" },
  { country: "Taiwan", prefix: "+886", flag: "https://flagcdn.com/tw.svg" },
  { country: "Tajikistan", prefix: "+992", flag: "https://flagcdn.com/tj.svg" },
  { country: "Tanzania", prefix: "+255", flag: "https://flagcdn.com/tz.svg" },
  { country: "Thailand", prefix: "+66", flag: "https://flagcdn.com/th.svg" },
  {
    country: "Timor-Leste",
    prefix: "+670",
    flag: "https://flagcdn.com/tl.svg"
  },
  { country: "Togo", prefix: "+228", flag: "https://flagcdn.com/tg.svg" },
  { country: "Tonga", prefix: "+676", flag: "https://flagcdn.com/to.svg" },
  {
    country: "Trinidad and Tobago",
    prefix: "+1-868",
    flag: "https://flagcdn.com/tt.svg"
  },
  { country: "Tunisia", prefix: "+216", flag: "https://flagcdn.com/tn.svg" },
  { country: "Turkey", prefix: "+90", flag: "https://flagcdn.com/tr.svg" },
  {
    country: "Turkmenistan",
    prefix: "+993",
    flag: "https://flagcdn.com/tm.svg"
  },
  { country: "Tuvalu", prefix: "+688", flag: "https://flagcdn.com/tv.svg" },
  { country: "Uganda", prefix: "+256", flag: "https://flagcdn.com/ug.svg" },
  { country: "Ukraine", prefix: "+380", flag: "https://flagcdn.com/ua.svg" },
  {
    country: "United Arab Emirates",
    prefix: "+971",
    flag: "https://flagcdn.com/ae.svg"
  },
  {
    country: "United Kingdom",
    prefix: "+44",
    flag: "https://flagcdn.com/gb.svg"
  },
  {
    country: "United States",
    prefix: "+1",
    flag: "https://flagcdn.com/us.svg"
  },
  { country: "Uruguay", prefix: "+598", flag: "https://flagcdn.com/uy.svg" },
  { country: "Uzbekistan", prefix: "+998", flag: "https://flagcdn.com/uz.svg" },
  { country: "Vanuatu", prefix: "+678", flag: "https://flagcdn.com/vu.svg" },
  {
    country: "Vatican City",
    prefix: "+39-06",
    flag: "https://flagcdn.com/va.svg"
  },
  { country: "Venezuela", prefix: "+58", flag: "https://flagcdn.com/ve.svg" },
  { country: "Vietnam", prefix: "+84", flag: "https://flagcdn.com/vn.svg" },
  { country: "Yemen", prefix: "+967", flag: "https://flagcdn.com/ye.svg" },
  { country: "Zambia", prefix: "+260", flag: "https://flagcdn.com/zm.svg" },
  { country: "Zimbabwe", prefix: "+263", flag: "https://flagcdn.com/zw.svg" }
];
