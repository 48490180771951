import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import { SiteService, TravelCompanion } from "@genezio-sdk/westtigermotors";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";

const TravelCompanionView = () => {
  const { t } = useTranslation();
  const [travelCompanions, setTravelCompanions] = useState<TravelCompanion[]>(
    []
  );
  useEffect(() => {
    async function asyncFn() {
      const res = await SiteService.getTravelCompanions();

      setTravelCompanions(res);
    }

    asyncFn();
  }, []);

  return (
    <>
      {localStorage.getItem("i18nextLng") === "ro" ? (
        <Helmet>
          <link
            rel="canonical"
            href={`https://firstgearclub.com/partener-de-calatorie`}
          />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/partener-de-calatorie`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/partener-de-calatorie?lang=en`}
          />

          <title>Gaseste un partener de tura - First Gear Club</title>
          <meta
            name="description"
            content="Descopera aventura off-road chiar si fara un partener de calatorie cu ⭐ Pachetul Travel Companion!"
          />
        </Helmet>
      ) : (
        <Helmet>
          <link
            rel="canonical"
            href={`https://firstgearclub.com/partener-de-calatorie`}
          />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/partener-de-calatorie`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/partener-de-calatorie?lang=en`}
          />

          <title>Find a Travel Companion - First Gear Club</title>
          <meta
            name="description"
            content="Discover off-road adventures even without a travel companion with ⭐ The Travel Companion Package!"
          />
        </Helmet>
      )}
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("travelCompanion.title")}`
          }
        ]}
        title={`${t("travelCompanion.title")}`}
      />
      {/* About */}
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h1 className="mb-4 primaryFont">{t("travelCompanion.title")}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("travelCompanion.text")
                }}
              />

              <button
                className="btn btn-primary mt-3"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                {t("travelCompanion.buttonLabel")}
              </button>
            </div>

            <div className="col-lg-3">
              {travelCompanions.map((elem, index) => (
                <Card className="mt-4" key={index}>
                  <Card.Body>
                    <h3 className="text-center mb-2">{elem.tourName}</h3>
                    <Card.Text className="text-center">
                      <b>{t("travelCompanion.limitedAvailabilityLabel")} </b>
                    </Card.Text>

                    <Card.Text className="text-center">
                      {dayjs(elem.date).format("YYYY-MM-DD")}
                    </Card.Text>

                    <Card.Text className="text-center">{elem.text}</Card.Text>

                    <button
                      className="btn btn-primary mt-3 w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `/tur/${elem?.tourName}-${elem.tourId}/${elem.date.toString()}/`;
                      }}
                    >
                      {t("travelCompanion.buttonLabelCompanion")}
                    </button>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TravelCompanionView;
