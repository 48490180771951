import React, { useEffect } from "react";
import { AdminService, Article, Section } from "@genezio-sdk/westtigermotors";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ArticlePage = () => {
  const [article, setArticle] = React.useState<Article | null>(null);
  const [loading, setLoading] = React.useState(true);

  const { articleId } = useParams<{ articleId: string }>();
  const { i18n } = useTranslation();

  const getTextSection = (section: Section) => {
    return (
      <>
        <h2 className="">
          {i18n.language === "ro" ? section.titleRo : section.titleEn}
        </h2>
        <p className="mt-3">
          {i18n.language === "ro" ? section.contentRo : section.contentEn}
        </p>
      </>
    );
  };

  const getGraphicSection = (section: Section) => {
    if (section.graphicType === "photo") {
      return (
        <img
          src={`${process.env.REACT_APP_STORAGE_BASE_URL}/articles/${article?.id}/${section.graphicUrl}`}
          alt={article?.titleRo}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "10px"
          }}
        />
      );
    }
    console.log(section.graphicUrl);

    return (
      <iframe
        src={section.graphicUrl}
        allow="autoplay; encrypted-media"
        allowFullScreen
        height="400"
        title="YouTube Video"
        style={{
          borderRadius: "10px"
        }}
      ></iframe>
    );
  };

  useEffect(() => {
    const fetchArticle = async () => {
      const localId = (articleId || "").split("-")[0];
      if (!localId) {
        return;
      }
      setLoading(true);
      const article = await AdminService.getArticleById(localId || "").catch(
        (e) => {
          console.error(e);
          return null;
        }
      );
      setLoading(false);

      if (!article) {
        return;
      }

      setArticle(article);
      setLoading(false);
    };

    fetchArticle();
  }, [articleId]);

  return (
    <Container>
      {loading && <div>Loading...</div>}
      {article && (
        <>
          <div className="mt-5">
            <h4
              style={{
                color: "gray"
              }}
              className="mt-3  text-center"
            >
              {i18n.language === "ro" ? article.dateRo : article.dateEn}
            </h4>
            <h1 className=" text-center">
              {i18n.language === "ro" ? article.titleRo : article.titleEn}
            </h1>
            <p className="mt-3">
              {i18n.language === "ro"
                ? article.descriptionRo
                : article.descriptionEn}
            </p>
          </div>

          <div className="my-4">
            {article.sections.map((section, index) => (
              <Row key={index} className="my-3">
                {section.textPlacement === "left" ? (
                  <>
                    <Col xs={12} md={6} className="my-2">
                      {getGraphicSection(section)}
                    </Col>
                    <Col xs={12} md={6} className="my-2">
                      {getTextSection(section)}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={12} md={6} className="my-2">
                      {getTextSection(section)}
                    </Col>
                    <Col xs={12} md={6} className="my-2">
                      {getGraphicSection(section)}
                    </Col>
                  </>
                )}
              </Row>
            ))}
          </div>
        </>
      )}
    </Container>
  );
};

export default ArticlePage;
