import { t } from "i18next";
import React, { useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { AdminService, Video } from "@genezio-sdk/westtigermotors";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Col } from "react-bootstrap";

const Videos = () => {
  const [videos, setVideos] = React.useState<Video[]>([]);

  useEffect(() => {
    async function asyncFn() {
      const res = await AdminService.getVideos();

      setVideos(res);
    }

    asyncFn();
  }, []);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("videos.title")}`
          }
        ]}
        title={`${t("videos.title")}`}
      />
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            {videos.map((elem) => (
              <Col lg={4} md={4} sm={6} xs={12} key={elem.id}>
                {elem.type === "youtube" ? (
                  <iframe
                    src={elem.url}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    height="315"
                    title="YouTube Video"
                  ></iframe>
                ) : elem.type === "vimeo" ? (
                  <iframe
                    src={elem.url}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    title="Vimeo Video"
                  ></iframe>
                ) : (
                  <video src={elem.url} controls autoPlay />
                )}
              </Col>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Videos;
