export const cars = [
  {
    image: "assets/img/first-cars/car-yellow.webp",
    label: "yellowCar"
  },
  {
    image: "assets/img/first-cars/car-ivory.webp",
    label: "ivoryCar"
  },
  {
    image: "assets/img/first-cars/car-blue.webp",
    label: "blueCar"
  },
  {
    image: "assets/img/first-cars/car-green.webp",
    label: "greenCar"
  },
  {
    image: "assets/img/first-cars/car-white.webp",
    label: "whiteCar"
  },
  {
    image: "assets/img/first-cars/car-black.webp",
    label: "blackCar"
  },
  {
    image: "assets/img/first-cars/car-silver.webp",
    label: "silverCar"
  },
  {
    image: "assets/img/first-cars/car-grey.webp",
    label: "greyCar"
  }
];
