import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../../components/Breadcrumbs";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`https://firstgearclub.com/politica-de-confidentialitate`}
        />

        <link
          rel="alternate"
          hrefLang="ro"
          href={`https://firstgearclub.com/politica-de-confidentialitate`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://firstgearclub.com/politica-de-confidentialitate?lang=en`}
        />
      </Helmet>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: `${t("privacyPolicyPage.title")}`
          }
        ]}
        title={`${t("privacyPolicyPage.title")}`}
      />
      <div className="main-wrapper">
        <div className="privacy-section">
          <div className="container">
            <h1>{t("privacyPolicyPage.title")}</h1>
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="terms-policy"
                  dangerouslySetInnerHTML={{
                    __html: t("privacyPolicyPage.content")
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
