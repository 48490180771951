import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function Testimonials() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section about-testimonial testimonials-section">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="title text-white primaryFont">
              {t("home.testimonials.title")}
            </h2>
            {/* <p className="description text-white">
              {t("home.testimonials.subtitle")}
            </p> */}
          </div>
          {/* /Heading title */}
          <Row className="about-testimonials testimonial-group mb-0">
            {/* /Carousel Item  */}
            {/* Carousel Item */}
            <Col md={6} className="testimonial-item d-flex">
              <div className="card flex-fill">
                <div className="card-body h-100">
                  <div className="quotes-head" />
                  <div className="review-box">
                    <div className="review-details ms-0">
                      <h6>{t("home.testimonials.testimonial1.title")}</h6>
                      <div className="list-rating">
                        <div className="list-rating-star">
                          {[...Array(Math.floor(5))].map((_, i) => (
                            <i key={i} className="fas fa-star filled" />
                          ))}
                        </div>
                        <p>
                          <span>(5)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>{t("home.testimonials.testimonial1.text")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} className="testimonial-item d-flex">
              <div className="card flex-fill">
                <div className="card-body h-100">
                  <div className="quotes-head" />
                  <div className="review-box">
                    <div className="review-details ms-0">
                      <h6>{t("home.testimonials.testimonial2.title")}</h6>
                      <div className="list-rating">
                        <div className="list-rating-star">
                          {[...Array(Math.floor(5))].map((_, i) => (
                            <i key={i} className="fas fa-star filled" />
                          ))}
                        </div>
                        <p>
                          <span>(5)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>{t("home.testimonials.testimonial2.text")}</p>
                </div>
              </div>
            </Col>

            <Col md={12} className="testimonial-item d-flex">
              <div className="card flex-fill">
                <div className="card-body h-100">
                  <div className="quotes-head" />
                  <div className="review-box">
                    <div className="review-details ms-0">
                      <h6>{t("home.testimonials.testimonial3.title")}</h6>
                      <div className="list-rating">
                        <div className="list-rating-star">
                          {[...Array(Math.floor(5))].map((_, i) => (
                            <i key={i} className="fas fa-star filled" />
                          ))}
                        </div>
                        <p>
                          <span>(5)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>{t("home.testimonials.testimonial3.text")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} className="testimonial-item d-flex">
              <div className="card flex-fill">
                <div className="card-body h-100">
                  <div className="quotes-head" />
                  <div className="review-box">
                    <div className="review-details ms-0">
                      <h6>{t("home.testimonials.testimonial4.title")}</h6>
                      <div className="list-rating">
                        <div className="list-rating-star">
                          {[...Array(Math.floor(5))].map((_, i) => (
                            <i key={i} className="fas fa-star filled" />
                          ))}
                        </div>
                        <p>
                          <span>(5)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>{t("home.testimonials.testimonial4.text")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} className="testimonial-item d-flex">
              <div className="card flex-fill">
                <div className="card-body h-100">
                  <div className="quotes-head" />
                  <div className="review-box">
                    <div className="review-details ms-0">
                      <h6>{t("home.testimonials.testimonial5.title")}</h6>
                      <div className="list-rating">
                        <div className="list-rating-star">
                          {[...Array(Math.floor(5))].map((_, i) => (
                            <i key={i} className="fas fa-star filled" />
                          ))}
                        </div>
                        <p>
                          <span>(5)</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>{t("home.testimonials.testimonial5.text")}</p>
                </div>
              </div>
            </Col>
            {/* /Carousel Item  */}
            {/* Carousel Item */}
            {/* /Carousel Item  */}
          </Row>
        </div>
      </section>
    </>
  );
}
