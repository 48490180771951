import React, { useState } from "react";
import { Button, Modal, Row, Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";
import "./buyVoucher.css";
import { Helmet } from "react-helmet";
import { Breadcrumbs } from "../../components/Breadcrumbs";

const BuyVoucher = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [type, setType] = useState(1);

  const [show, setShow] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function buyVoucher(type: number) {
    setIsGenerating(true);
    const res = await SiteService.createVoucherPaymentIntent(type, email).catch(
      (err) => {
        console.error(err);
      }
    );
    setIsGenerating(false);

    if (res) {
      window.location.href = res;
    }
  }

  return (
    <>
      {localStorage.getItem("i18nextLng") === "ro" ? (
        <Helmet>
          <link
            rel="canonical"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou`}
          />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou?lang=en`}
          />

          <title>
            Experienta cadou: Voucher off-road cadou - First Gear Club
          </title>
          <meta
            name="description"
            content="Ofera un cadou memorabil! Surprinde-l cu un voucher cadou pentru o experienta off-road de neuitat ⭐ Trasee provocatoare si peisaje impresionante."
          />
        </Helmet>
      ) : (
        <Helmet>
          <link
            rel="canonical"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou`}
          />

          <link
            rel="alternate"
            hrefLang="ro"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou`}
          />
          <link
            rel="alternate"
            hrefLang="en"
            href={`https://firstgearclub.com/experienta-off-road-voucher-cadou?lang=en`}
          />

          <title>Off-road Gift Voucher - First Gear Club</title>
          <meta
            name="description"
            content="Give a memorable gift! Surprise them with a gift voucher for an unforgettable off-road experience ⭐ Challenging routes and breathtaking landscapes."
          />
        </Helmet>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        style={{ margin: "20vh auto 0px auto" }}
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title>{t(`buyVoucherPage.pleaseFillEmail`)}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-4">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="p-4">
          <Button
            variant="primary"
            className="w-100 m-0"
            onClick={() => {
              buyVoucher(type);
            }}
          >
            {t(`buyVoucherPage.package${type.toString()}.buttonLabel`)}
            {" - "}
            {type === 1 ? "€ 300 " : "€ 500 "} voucher{" "}
            {isGenerating && <Spinner animation="border" size="sm" />}
          </Button>
        </Modal.Footer>
      </Modal>
      <Breadcrumbs
        items={[
          {
            link: `#`,
            name: "Gift Voucher"
          }
        ]}
        title={`Gift Voucher`}
      />
      {/* About */}
      <section className="section pricing-section">
        <div className="container">
          <Row className="justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="mb-4 primaryFont">{t("buyVoucherPage.title")}</h1>
              <h4 className="mb-3">{t("buyVoucherPage.subtitle")}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("buyVoucherPage.text")
                }}
              />
            </div>
          </Row>
          <Row className="align-items-center mt-4 justify-content-evenly">
            {[1, 2].map((item, index) => (
              <div
                className="col-lg-5 col-md-5 col-12"
                key={index}
                // data-aos="fade-down"
                // data-aos-duration={1200}
                // data-aos-delay={200}
              >
                <div className="price-card p-0">
                  <div className="price-head pb-3 mb-3 p-0">
                    <div className="price-level mb-0 p-0">
                      <img
                        src={`assets/img/voucher__photo_${item === 1 ? "300" : "500"}.jpg`}
                        style={{ cursor: "pointer" }}
                        className="img-fluid"
                        alt="banner-5"
                        loading="lazy"
                        onClick={(e) => {
                          e.preventDefault();
                          setType(item);
                          handleShow();
                        }}
                      />
                      {/* <h6>
                        {t(`buyVoucherPage.package${item.toString()}.title`)}
                      </h6>
                      <h4 className="my-3">{item === 1 ? "€ 300" : "€ 500"}</h4> */}
                      <p className="mt-2 px-5">
                        {t(
                          `buyVoucherPage.package${item.toString()}.perfectFor`
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="price-details px-3 pb-3">
                    <ul>
                      <li className="price-check">
                        <span>
                          <i className="fa-regular fa-circle-check" />
                        </span>
                        {t(`buyVoucherPage.package${item.toString()}.duration`)}
                      </li>
                      <li className="price-check">
                        <span>
                          <i className="fa-regular fa-circle-check" />
                        </span>
                        {t(
                          `buyVoucherPage.package${item.toString()}.whatsIncluded`
                        )}
                      </li>
                      <li className="price-check">
                        <span>
                          <i className="fa-regular fa-circle-check" />
                        </span>
                        {t(`buyVoucherPage.package${item.toString()}.text`)}
                      </li>
                    </ul>
                    <div>
                      <Button
                        className="btn viewdetails-btn w-100 m-0"
                        onClick={(e) => {
                          e.preventDefault();
                          setType(item);
                          handleShow();
                        }}
                      >
                        {t(
                          `buyVoucherPage.package${item.toString()}.buttonLabel`
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </section>
    </>
  );
};

export default BuyVoucher;
