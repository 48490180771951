import React from "react";
import ImageWithBasePath from "../../../core/data/img/ImageWithBasePath";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Error404 = () => {
  const { t } = useTranslation();
  return (
    <div className="main-wrapper">
      <div className="error-box">
        <ImageWithBasePath
          src="assets/img/404.png"
          className="img-fluid"
          alt="Page not found"
        />
        <h3>{t("errorPage.title")}</h3>
        <p>{t("errorPage.subtitle")}</p>
        <div className="back-button">
          <Link to={"/"} className="btn btn-primary">
            {t("errorPage.buttonLabel")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error404;
