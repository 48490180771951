/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { SiteService } from "@genezio-sdk/westtigermotors";
import { useTranslation } from "react-i18next";

export function ContactForm() {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [isSending, setIsSending] = useState(false);

  async function handleSubmit() {
    if (!name || !email || !phone || !message) {
      setError("Please fill all fields");
      return;
    }

    const emailReg = /\S+@\S+\.\S+/;

    if (!emailReg.test(email)) {
      setError("Invalid email");
      return;
    }

    setError("");
    setIsSending(true);
    const res = await SiteService.sendContactEmail(
      name,
      email,
      phone,
      message
    ).catch((err) => {
      setError("Failed to send message");
      console.error(err);
    });

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: "generate_lead"
    });

    if (!res) {
      setIsSending(false);
      return;
    }
    setSuccess("Message sent successfully");
    setIsSending(false);
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          <div className="input-block">
            <label>
              {t("contact.nameLabel")} <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="input-block">
            <label>
              {t("contact.emailLabel")} <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="input-block">
            <label>
              {t("contact.phoneLabel")} <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="input-block">
            <label>
              {t("contact.messageLabel")} <span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              rows={4}
              cols={50}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
      </div>
      <button
        className="btn contact-btn"
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {isSending
          ? t("contact.sendingButtonLabel")
          : t("contact.sendButtonLabel")}
        {isSending && <Spinner animation="border" size="sm" />}
      </button>
    </>
  );
}
